<template>

  <!-- {{user}} -->
  <div class="col-lg-9 col-md-9">
    <!-- 推薦不顯示新增貼文 -->
    <div class="col-sm-12">
      <div class="card" id="post-modal-data">
        <div class="card-header d-flex justify-content-between">
          <div class="header-title">
            <h4 class="card-title">{{ $t('label.search') }}</h4>
          </div>
          <div class="card-header-toolbar d-flex align-items-center"></div>
        </div>
        <div class="card-body iq-card iq-card-block iq-card-stretch iq-card-height">
          <div v-if="activeName === 'second'" class="input-group mb-1">
            <!-- <el-select @change="remoteSearch" @remove-tag="remoteSearch" size="large" clearable v-model="tags" multiple :placeholder="$t('label.selectTag')" style="width: 100%">
              <el-option v-for="item in commomData.tagItems" :key="item.value" :label="item.label" :value="item.value" />
            </el-select> -->
            <PreferTagList :userForm="userForm" :addTags="addTags" />
          </div>
          <div class="search-field">
            <el-input v-model="keyWork" clearable :placeholder="$t('label.enterCreatorOrPost')" class="input-with-select" size="large" @input="remoteSearch">
              <template #prepend>
                <el-icon>
                  <Search />
                </el-icon>
              </template>
            </el-input>
          </div>
          <tab-content id="pills-tabContent-2">
            <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
              <el-tab-pane :label="$t('label.creator')" name="first">
                <div v-if="users.length === 0 && activeName === 'first'" class="d-flex justify-content-center">
                  <span>{{ $t('label.noInformation') }}</span>
                </div>
                <div v-else>
                  <div v-for="(r, index) in users" :key="index" class="col-12 d-flex pb-1 search-wrap" @click="toLink(r.account)">
                    <el-avatar :size="50" :src="r.account" @error="errorHandler">
                      <img :src="r.avatar" />
                    </el-avatar>
                    <div class="account-list m-1">
                      <p class="account-p1 mb-0 ms-2 text-dark">{{r.user_name}}</p>
                      <p class="account-p2 mb-0 ms-2 text-secondary">{{r.account}}</p>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="$t('label.post')" name="second">

                <div v-if="list.length === 0 && activeName === 'second'" class="d-flex justify-content-center">
                  <span>{{ $t('label.noInformation') }}</span>
                </div>
                <div v-else>
                  <SocialPost :post="list" :settions="[]" :showSettions="false" />
                </div>
                <!-- <div v-if="!onLoading" class="col-sm-12 text-center">
                    <img src="@/assets/images/page-img/page-load-loader.gif" alt="loader" style="height: 100px" />
                  </div> -->
              </el-tab-pane>
            </el-tabs>
          </tab-content>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { socialvue } from '../../../config/pluginInit'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import { getPost } from '@/api/post'
import { ref, getCurrentInstance } from 'vue'
import { Search } from '@element-plus/icons-vue'
import SocialPost from './Components/SocialPost'
import { getOtherProfile } from '@/api/user'
import { useRouter } from 'vue-router'
import PreferTagList from '../../User/Components/PreferTagList'

export default {
  name: 'SocialSearch',
  components: { Search, SocialPost, PreferTagList },
  setup(props) {
    const tags = ref([])
    const select = ref('')
    const keyWork = ref('')
    const users = ref([])
    const { proxy } = getCurrentInstance()
    const onLoading = ref(false)
    const setTabs = ref('first')
    const router = useRouter()

    console.log(props)
    const activeName = ref('first')
    const handleClick = tab => {
      proxy.commomData.condition.page = 1
      if (tab.props.name === 'first') {
        setTabs.value = 'first'

        remoteSearch(keyWork.value)
      }
      if (tab.props.name === 'second') {
        setTabs.value = 'second'
        proxy.commomData.noResult = false

        proxy.getRows()
      }
    }
    const settions = ref({})

    const toLink = val => {
      router.push({ path: val })
    }

    const remoteSearch = async val => {
      proxy.commomData.condition.page = 1
      if (!keyWork.value) {
        users.value = []
        proxy.list = []
        return
      }
      onLoading.value = true
      proxy.commomData.noResult = false
      if (setTabs.value === 'first') {
        const query = {
          user_account: `${val}`,
          type: 'list',
          keyword: 'tags',
          search: tags.value.join()
        }

        await getOtherProfile(query)
          .then(res => {
            onLoading.value = false
            users.value = res.user
          })
          .catch(err => {
            onLoading.value = false
            console.log(err)
          })
      } else {
        proxy.getRows()
      }
    }

    const addTags = tagName => {
      if (proxy.userForm.tags.includes(tagName)) {
        proxy.userForm.tags = proxy.userForm.tags.filter(item => item !== tagName)
        return
      }
      proxy.userForm.tags.push(tagName)
      remoteSearch(keyWork.value)
    }

    return {
      select,
      settions,
      activeName,
      handleClick,
      SocialPost,
      keyWork,
      remoteSearch,
      users,
      onLoading,
      toLink,
      tags,
      addTags
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState',
      planDate: 'User/planDateState'
    })
  },
  created() {},
  mounted() {
    socialvue.index()
    // this.getRows()
  },
  data() {
    return {
      list: [],
      primaryKey: 'created_at',
      commomData: commomData(),
      userForm: {
        tags: []
      }
    }
  },
  methods: {
    async getRows() {
      if (this.commomData.noResult) {
        return false
      }
      if (!this.keyWork) {
        return
      }
      this.commomData.listLoader = true
      this.commomData.condition.order_by = this.primaryKey
      this.commomData.condition.post_type = 1
      this.commomData.condition.query = this.keyWork

      await getPost(this.commomData.condition)
        .then(res => {
          if (this.commomData.condition.page === 1) {
            this.list = res.data
            this.commomData.condition.page = 2
            this.commomData.listLoader = false
            if (res.total === 0) {
              this.commomData.noResult = true
            }
          } else if (res.total > this.list.length) {
            this.list.push(...res.data)
            this.commomData.condition.page = this.commomData.condition.page + 1
            this.commomData.listLoader = false
          } else if (res.total === this.list.length) {
            this.commomData.noResult = true
            this.commomData.listLoader = false
          } else {
            this.commomData.noResult = true
          }
        })
        .catch(err => {
          console.log(err)
          // this.$swal.mixin().fire({
          //   icon: 'error',
          //   title: 'err.response.data.message'
          // })
        })
    }
  }
}
</script>
